import './css/main.css';

import barba from '@barba/core';
import { gsap } from "gsap";

barba.init({
    debug: true,
    timeout: 7000,
    transitions: [
        {
            name: 'opacity-transition',
            leave(data) {
                return gsap.to(data.current.container, {
                    opacity: 0
                });
            },
            enter(data) {

            },
            after(data) {

                // isotopeCheck();
                return gsap.from(data.next.container, {
                    opacity: 0
                });


            },
            afterOnce(data) {
                // 
            },
            once(data) {
                // hamburgerToggle("o");
                plyrCheck();
                galleryCheck();
                zoomImageCheck();
                isotopeCheck();
            }
        }],
});

window.barba = barba;

function plyrCheck() {
    let plyrs = document.getElementsByClassName('customPlayer');
    if (plyrs.length > 0) {
        import(/* webpackChunkName: "customPlayer" */ "./js/components/customPlayer.js").then(module => {
            const playerGo = module.default;
            playerGo();
        });
    };
};

function galleryCheck() {
    let galleries = document.getElementsByClassName('gallery');
    if (galleries.length > 0) {
        import(/* webpackChunkName: "gallery" */ "./js/components/gallery.js").then(module => {
            const galleryGo = module.default;
            galleryGo();
        });
    };
};

function scrollToTop() {
    // window.scrollTo({top: 0, behavior: 'smooth'});
    window.scrollTo({ top: 0 });
};

function zoomImageCheck() {
    let zoom = document.getElementsByClassName('zoomable-image');
    if (zoom.length > 0) {
        import(/* webpackChunkName: "imageZoon" */ "./js/components/imageZoom.js").then(module => {
            const zoomGo = module.default;
            zoomGo();
        });
    }
};

function isotopeCheck() {
    let iso = document.getElementsByClassName('isoGrid');
    if (iso.length > 0) {
        import(/* webpackChunkName: "isotope" */ "./js/components/isotope.js").then(module => {
            const isoGo = module.default;
            isoGo();
        });
    }
};

function galleryLoopCheck(data) {
    console.log(data.trigger);
    if (data.trigger.nodeName === 'A' || data.trigger === 'barba') {
    // if (data.trigger.classList.contains('loopLink')) {
        import(/* webpackChunkName: "galleryLoop" */ "./js/components/galleryLoop.js").then(module => {
            const galleryLoopGo = module.default;
            galleryLoopGo(data);
        });
        // console.log('Gallery trigger');
    // };
    };
};

function hamburgerToggle(preset) {
    import(/* webpackChunkName: "hamburgerToggle" */ "./js/components/hamburgerToggle.js").then(module => {
      const toggle = module.default;
      toggle(preset);
    });
}
  
let dropdownCloser = document.getElementById('dropdownCloser');
dropdownCloser.addEventListener('click', function() {hamburgerToggle('c')});

let hamburgerOuter = document.getElementById("hamburgerOuter");
hamburgerOuter.addEventListener("click", function() {hamburgerToggle()});


barba.hooks.beforeEnter((data) => {
    scrollToTop();
});

barba.hooks.after((data) => {
    galleryLoopCheck(data);
    plyrCheck();
    galleryCheck();
    zoomImageCheck();
    isotopeCheck();
    
});
